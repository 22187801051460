import { useEffect, useRef, useState } from 'react';

import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid';
import PhoneInput from 'react-phone-number-input';
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getCountries } from '../../api/services/Country';
import { register } from '../../api/services/User';
import DynamicIcon from '../../components/DynamicIcon';
import Loader from '../../components/Loader';
import Logo from '../../components/Logo';

const RegisterScreen = () => {
  const navigate = useNavigate();

  const errRef = useRef();

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [postCode, setPostCode] = useState('');
  const [country, setCountry] = useState({
    id: '',
    countryName: '',
    countryCode: '',
    currency: ''
  });
  const [countryList, setCountryList] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [cvrNumber, setCvrNumber] = useState(''); // REVIEW: maybe change to VAT number
  const [currency, setCurrency] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [terms, setTerms] = useState(false);
  const [type, setType] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    // setSubmitting(false);
    setErrorMsg('');
  }, [email, name, password, repeatPassword, address, phoneNumber, currency, postCode, country, firstName, lastName, type, cvrNumber, isPasswordValid]);

  useEffect(() => {
    getAllCountries();
  }, []);

  const getAllCountries = async () => {
    try {
      const response = await getCountries();

      // only add Denmark to the list as other countries are not supported yet
      const filteredCountries = response.data.filter((country) => country.countryCode === 'DK');
      setCountryList(filteredCountries);
    } catch (err) {
      console.log(err);
    }
  };

  const clearForm = () => {
    setEmail('');
    setPassword('');
    setRepeatPassword('');
    setPhoneNumber('');
    setCurrency('');
    setCountry();
    setPostCode('');
    setAddress('');
    setFirstName('');
    setLastName('');
    setCvrNumber('');
    setType('fleet');
    setTerms(false);
    setName('');
  };

  const handlePasswordChange = (event) => {
    // setPassword(event.target.value);
    setPassword(event.target.value.replace(/\s/g, ''));
    setIsPasswordValid(/^(?=.*[0-9!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(event.target.value));
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleCountrySelect = (value) => {
    if (value === '') {
      setCountry('');
      setCurrency('');
    } else {
      const selected = countryList?.find((c) => c?.id == value);

      setCountry(selected);
      setCurrency(selected?.currency);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    // if (password !== repeatPassword && !isPasswordValid) {
    if (password !== repeatPassword) {
      setErrorMsg('Passwords do not match');
      errRef.current.focus();
      setSubmitting(false);
      return;
    }
    try {
      const data = {
        email,
        password,
        postCode,
        countryId: country.id,
        phoneNumber,
        currency,
        type,
        address,
        firstName,
        lastName,
        name,
        cvrNumber
      };

      const response = await register(data);
      console.log(response);

      if (response?.status === 201) {
        toast.success('Registered successfully!');
        toast.warn('You have to wait to be approved before using the platform.');
        clearForm();
        navigate('/login');
      } else if (!response || response?.status === 409) {
        setErrorMsg('User already exists. Please login instead!');
      } else if (response.status === 500) {
        setErrorMsg('Something went wrong. Please try again later');
      } else {
        setErrorMsg('Something went wrong, login failed...');
      }
      setSubmitting(false);
    } catch (err) {
      console.log(err);
      setErrorMsg('Registration Failed');
      setSubmitting(false);
    }
    errRef.current.focus();
    setTimeout(() => {
      setErrorMsg(false);
    }, 3000);
  };

  const toggleTerms = () => {
    setTerms(!terms);
  };

  return (
    <>
      {countryList && (
        <div className="flex items-center justify-center min-h-full px-4 py-12 sm:px-6 lg:px-8">
          <div className="w-full max-w-md space-y-8">
            <div>
              <Logo />
              <h2 className="mt-6 text-3xl font-extrabold text-center text-gray-900">
                Create your <span className="text-blue">{type}</span> account
              </h2>
            </div>
            {/* TODO: write a better error message and style it */}
            <p ref={errRef} className={errorMsg ? 'text-red-500 text-center' : 'hidden'} aria-live="assertive">
              {errorMsg}
            </p>
            <form className="mt-8 space-y-6" action="#" method="POST" onSubmit={handleRegister}>
              <input type="hidden" name="remember" defaultValue="true" />
              <div className="-space-y-px rounded-md">
                <div>
                  <select
                    id="type"
                    name="type"
                    onChange={(e) => setType(e.target.value)}
                    value={type}
                    autoComplete="type"
                    className={`${
                      type === '' ? 'text-gray-500' : 'text-gray-900'
                    } relative block w-full px-3 py-2 mt-2 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:outline-none focus:ring-blue focus:border-blue focus:z-10 sm:text-sm`}>
                    <option disabled value={''} className="">
                      *Select account type
                    </option>
                    <option value="fleet">Fleet</option>
                    <option value="business">Business</option>
                  </select>
                </div>

                <div>
                  <select
                    id="country"
                    name="country"
                    onChange={(e) => handleCountrySelect(e.target.value)}
                    value={country.id}
                    autoComplete="country"
                    className={`${
                      country.id === '' ? 'text-gray-500' : 'text-gray-900'
                    } relative block w-full px-3 py-2 placeholder-gray-500 border-b border-gray-300 appearance-none rounded-none focus:outline-none focus:ring-blue focus:border-blue focus:z-10 sm:text-sm`}
                    placeholder="*Select Country">
                    <option disabled value={''} className="">
                      *Select country
                    </option>
                    {countryList.map((country) => (
                      <option key={country.id} value={country.id}>
                        {country.countryName}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label htmlFor="currency" className="sr-only">
                    Currency
                  </label>
                  <input
                    disabled
                    id="currency"
                    name="currency"
                    type="text"
                    autoComplete="currency"
                    // onChange={(e) => setCurrency(e.target.value)}
                    value={currency}
                    required
                    className="relative block w-full px-3 py-2 mb-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none cursor-not-allowed rounded-b-md focus:outline-none focus:ring-blue focus:border-blue focus:z-10 sm:text-sm"
                    placeholder="*Currency"
                  />
                </div>

                <div className="">
                  <label htmlFor="phoneNumber" className="sr-only">
                    Phone number
                  </label>
                  <div className="mt-1">
                    <PhoneInput international defaultCountry={country ? country?.countryCode : 'DK'} value={phoneNumber} onChange={setPhoneNumber} />
                  </div>
                </div>

                <div>
                  <label htmlFor="name" className="sr-only">
                    Fleet Name
                  </label>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    autoComplete="name"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    required
                    className="relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:outline-none focus:ring-blue focus:border-blue focus:z-10 sm:text-sm"
                    placeholder={type === 'fleet' ? '*Fleet name' : '*Business name'}
                  />
                </div>
                <div>
                  <label htmlFor="email" className="sr-only">
                    Email
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                    className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none focus:outline-none focus:ring-blue focus:border-blue focus:z-10 sm:text-sm"
                    placeholder="*Email address"
                  />
                </div>
                <div>
                  <label htmlFor="firstName" className="sr-only">
                    First Name
                  </label>
                  <input
                    id="firstName"
                    name="firstName"
                    type="text"
                    autoComplete="firstName"
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                    required
                    className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none focus:outline-none focus:ring-blue focus:border-blue focus:z-10 sm:text-sm"
                    placeholder="*First name"
                  />
                </div>
                <div>
                  <label htmlFor="lastName" className="sr-only">
                    Last Name
                  </label>
                  <input
                    id="lastName"
                    name="lastName"
                    type="text"
                    autoComplete="lastName"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                    required
                    className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none focus:outline-none focus:ring-blue focus:border-blue focus:z-10 sm:text-sm"
                    placeholder="*Last name"
                  />
                </div>
                <div>
                  <label htmlFor="vatNumber" className="sr-only">
                    VAT Number
                  </label>
                  <input
                    id="vatNumber"
                    name="vatNumber"
                    type="number"
                    autoComplete="vatNumber"
                    onChange={(e) => setCvrNumber(e.target.value)}
                    value={cvrNumber}
                    required
                    className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none focus:outline-none focus:ring-blue focus:border-blue focus:z-10 sm:text-sm"
                    placeholder="*VAT number"
                  />
                </div>
                <div>
                  <label htmlFor="address" className="sr-only">
                    Address
                  </label>
                  <input
                    id="address"
                    name="address"
                    type="text"
                    autoComplete="address"
                    onChange={(e) => setAddress(e.target.value)}
                    value={address}
                    required
                    className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none focus:outline-none focus:ring-blue focus:border-blue focus:z-10 sm:text-sm"
                    placeholder="*Address"
                  />
                </div>
                <div>
                  <label htmlFor="postCode" className="sr-only">
                    Post Code
                  </label>
                  <input
                    id="postCode"
                    name="postCode"
                    type="number"
                    autoComplete="postCode"
                    onChange={(e) => setPostCode(e.target.value)}
                    value={postCode}
                    required
                    className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 appearance-none rounded-b-md focus:outline-none focus:ring-blue focus:border-blue focus:z-10 sm:text-sm"
                    placeholder="*Post code"
                  />
                </div>

                <div className="relative block">
                  <label htmlFor="password" className="sr-only">
                    Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type={!showPassword ? 'password' : 'text'}
                    autoComplete="current-password"
                    // onChange={(e) => setPassword(e.target.value)}
                    onChange={handlePasswordChange}
                    value={password}
                    required
                    className="relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:outline-none focus:ring-blue focus:border-blue focus:z-10 sm:text-sm"
                    placeholder="*Password"
                  />
                  <div
                    className="absolute inset-y-0 right-0 flex items-center pr-3 text-sm leading-5 text-gray-500 cursor-pointer"
                    onClick={handleClickShowPassword}>
                    {showPassword ? <DynamicIcon icon="EyeIcon" /> : <DynamicIcon icon="EyeSlashIcon" />}
                  </div>
                </div>
                <div>
                  <label htmlFor="repeatPassword" className="sr-only">
                    Repeat Password
                  </label>
                  <input
                    id="repeatPassword"
                    name="repeatPassword"
                    type="password"
                    // autoComplete="current-password"
                    onChange={(e) => setRepeatPassword(e.target.value)}
                    value={repeatPassword}
                    required
                    className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-b-md focus:outline-none focus:ring-blue focus:border-blue focus:z-10 sm:text-sm"
                    placeholder="*Repeat password"
                  />
                </div>
                <div className="mt-1">
                  {password.length > 0 && !isPasswordValid && (
                    <div className="flex items-center mt-1 text-sm text-red-500">
                      <ExclamationCircleIcon className="w-4 h-4 mr-2" />
                      Password must contain at least 8 characters, capital letter and one special character.
                    </div>
                  )}
                  {isPasswordValid && (
                    <div className="flex items-center mt-1 text-green-500">
                      <CheckCircleIcon className="w-4 h-4 mr-2" />
                      Password is valid.
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center mt-2 space-x-2">
                <input
                  id="termsAndConditions"
                  name="Terms and Conditions"
                  type="checkbox"
                  onChange={toggleTerms}
                  checked={terms}
                  className="w-5 h-5 bg-transparent border-2 rounded-md border-dark text-blue focus:ring-0 hover:cursor-pointer"
                />
                <label htmlFor="termsAndConditions" className="block ml-2 text-sm hover:cursor-pointer">
                  I have read and accepted{' '}
                  <a className="underline text-blue" href="https://lastlap.delivery/tc/" target="_blank" rel="noopener noreferrer">
                    {' '}
                    Terms and Conditions{' '}
                  </a>
                </label>
              </div>
              <div>
                <button
                  disabled={
                    !email.length ||
                    !type.length ||
                    !firstName.length ||
                    !lastName.length ||
                    !address.length ||
                    !postCode.length ||
                    !country.id.length ||
                    !phoneNumber ||
                    !currency.length ||
                    !cvrNumber.length ||
                    !password.length ||
                    !repeatPassword.length ||
                    !terms === true ||
                    !isPasswordValid ||
                    submitting
                  }
                  type="submit"
                  className={`${
                    submitting ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue cursor-pointer'
                  } relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md group disabled:bg-gray-300 disabled:cursor-not-allowed hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue`}>
                  Create an account
                  <span>
                    {submitting && (
                      <div className="ml-2">
                        <Loader small={true} />
                      </div>
                    )}
                  </span>
                </button>

                <div className="flex flex-col items-center justify-between mt-6">
                  <div className="flex items-center">
                    <label htmlFor="remember-me" className="block ml-2 text-sm text-gray-900">
                      Already have an account?
                    </label>
                  </div>

                  <div className="text-sm">
                    <NavLink to="/login" className="font-medium text-blue hover:text-blue">
                      Sign In
                    </NavLink>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default RegisterScreen;
