import { useEffect, useRef, useState } from 'react';

import PhoneInput from 'react-phone-number-input';
import { toast } from 'react-toastify';

import Button from '../../../Shared/components/Button';
import 'react-phone-number-input/style.css';
import useAuth from '../../../Shared/hooks/useAuth';
import { updateMyUser } from '../../api/services/User';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const UserDetailsForm = ({ closeModal }) => {
  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');

  const [errorMsg, setErrorMsg] = useState('');
  const axiosPrivate = useAxiosPrivate();

  const { user, setUser } = useAuth();
  const errRef = useRef();

  const updateMyUserUrl = updateMyUser();

  const [sound, setSound] = useState(true);

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setPhoneNumber(user.phoneNumber);
      setEmail(user.email);
      setSound(user.soundEnabled);
    }
  }, [user]);

  const handleSubmit = async () => {
    const data = { firstName, lastName, phoneNumber, email, soundEnabled: sound };
    setLoading(true);

    try {
      const response = await axiosPrivate.put(updateMyUserUrl, data);

      toast.success('User data edited!');
      closeModal();
      setLoading(false);
      setUser({ ...user, ...response.data });
    } catch (err) {
      console.log(err);
      setErrorMsg('Failed to edit user, please try again later');
      errRef.current.focus();
      setLoading(false);
    }
  };

  return (
    <>
      <div className="mt-5">
        <p ref={errRef} className={errorMsg ? 'text-red-500 text-center' : 'hidden'} aria-live="assertive">
          {errorMsg}
        </p>
        <form action="#" method="POST" onSubmit={handleSubmit}>
          <div className="overflow-hidden shadow sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
              <div className="grid grid-cols-4 gap-6">
                <div className="col-span-4 sm:col-span-2">
                  <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                    First Name
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                    autoComplete="firstName"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>

                <div className="col-span-4 sm:col-span-2">
                  <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                    autoComplete="lastName"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>

                <div className="col-span-4 sm:col-span-2">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    autoComplete="email"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>

                {user?.type === 'fleet' && (
                  <div className="col-span-4 sm:col-span-2">
                    <label htmlFor="sound" className="block text-sm font-medium text-gray-700">
                      Sound preference
                    </label>
                    <select
                      id="sound"
                      name="sound"
                      autoComplete="sound"
                      onChange={(e) => setSound(e.target.value === 'true')}
                      value={sound ? 'true' : 'false'}
                      className="block w-full px-3 py-2 mt-1 placeholder-gray-500 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue focus:border-blue sm:text-sm">
                      <option value={'true'}>Enabled</option>
                      <option value={'false'}>Disabled</option>
                    </select>
                  </div>
                )}

                <div className="col-span-4 sm:col-span-4">
                  <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
                    Phone number
                  </label>
                  <div className="mt-1">
                    <PhoneInput international defaultCountry="DK" value={phoneNumber} onChange={setPhoneNumber} />
                  </div>
                </div>
              </div>

              <div className="flex justify-center mt-6 space-x-5">
                <Button text="Cancel" onClick={closeModal} bg="bg-white" border="border-blue" textColor="text-blue" rounded />
                <Button text="Save" onClick={handleSubmit} disabled={!firstName.length || !lastName.length} rounded />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default UserDetailsForm;
