import React from 'react';

import { NavLink, useMatch } from 'react-router-dom';

import Dropdown from '../../../Shared/components/Dropdown';
import permissionCheck from '../../../Shared/helpers/permissionCheck';
import useAuth from '../../../Shared/hooks/useAuth';

const FleetNavigation = () => {
  const { user } = useAuth();

  const driverPath = useMatch({
    path: '/fleet/drivers/:slug',
    exactly: false
  });

  const salesPath = useMatch({
    path: '/fleet/sales/:slug',
    exactly: false
  });

  const driverSubPages = [
    { name: 'All Drivers', url: '/fleet/drivers/all-drivers', id: 1, action: 'drivers-all-drivers:see' },
    { name: 'Shifts', url: '/fleet/drivers/shifts', id: 2, action: 'drivers-shifts:see' },
    { name: 'Salaries', url: '/fleet/drivers/salaries', id: 3, action: 'drivers-salaries:see:' },
    { name: 'Contracts', url: '/fleet/drivers/contracts', id: 4, action: 'drivers-contracts:see:' },
    { name: 'Bonuses', url: '/fleet/drivers/bonuses', id: 5, action: 'drivers-bonuses:see' },
    { name: 'Cities & Fleet Codes', url: '/fleet/drivers/cities-and-fleet-codes', id: 6, action: 'drivers-cities-and-fleet-codes:see' }
  ];

  const salesSubPages = [
    { name: 'Sales Analytics', url: '/fleet/sales/analytics', id: 1, action: 'sales-analytics:see' },
    { name: 'Delivery History', url: '/fleet/sales/delivery-history', id: 2, action: 'sales-delivery-history:see' },
    { name: 'Pricing', url: '/fleet/sales/pricing', id: 3, action: 'sales-pricing:see' }
    // { name: 'Balance & Withdraw', url: '/fleet/sales/balance-and-withdraw', id: 4, action: 'sales-balance-and-withdraw:see' }
  ];

  const check = (action) => {
    const permissionGranted = permissionCheck(action, user?.role, 'fleet');
    return permissionGranted;
  };

  const inactiveLinkStyle = 'text-white py-2 rounded-lg md:rounded-none px-8 py-4 bg-dark z-10 hover:bg-blue duration-300 transition-all ease-in-out';
  const activeLinkStyle = 'text-blue text-white font-bold rounded-lg md:rounded-none bg-blue px-8 py-4';
  const linkStyle = 'text-sm hover:cursor-pointer';

  return (
    <div className="flex flex-col flex-wrap items-center justify-center mb-5 space-x-0 space-y-2 uppercase md:space-y-0 md:-mb-5 md:flex-row lg:space-y-0 lg:rounded-xl lg:flex-row w-max">
      {check('live-deliveries:see') && (
        <NavLink
          to="/fleet/live-deliveries"
          className={({ isActive }) => (isActive ? activeLinkStyle : inactiveLinkStyle) + ' md:border-r-[#48676D] md:border-r-[1px] md:rounded-l-lg'}>
          <p className={linkStyle}>Live Deliveries</p>
        </NavLink>
      )}

      {check('drivers:see') && <Dropdown path={driverPath} subPages={driverSubPages} check={check} title="Drivers" />}

      {check('sales:see') && <Dropdown path={salesPath} subPages={salesSubPages} check={check} title="Sales" />}

      {check('customers:see') && (
        <NavLink
          to="/fleet/customers"
          className={({ isActive }) => (isActive ? activeLinkStyle : inactiveLinkStyle) + ' md:border-r-[#48676D] md:border-r-[1px]'}>
          <p className={linkStyle}>Customers</p>
        </NavLink>
      )}

      {check('users:see') && (
        <NavLink to="/fleet/users" className={({ isActive }) => (isActive ? activeLinkStyle : inactiveLinkStyle) + ' md:border-r-[#48676D] md:border-r-[1px]'}>
          <p className={linkStyle}>Users</p>
        </NavLink>
      )}

      {check('contact:see') && (
        <NavLink to="/fleet/contact" className={({ isActive }) => (isActive ? activeLinkStyle : inactiveLinkStyle) + ' md:rounded-r-lg'}>
          <p className={linkStyle}>Contact</p>
        </NavLink>
      )}
    </div>
  );
};

export default FleetNavigation;
