import { Fragment, useState } from 'react';

import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import { assignDeliveryToDriver, unassignDeliveryFromDriver } from '../../Fleet/api/services/DeliveryService';
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import Button from './Button';
import DynamicIcon from './DynamicIcon.jsx';

const DeliveryDetails = ({ closeModal, delivery, drivers, type }) => {
  const [driverId, setDriverId] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  const assignDeliveryUrl = assignDeliveryToDriver(delivery?.id, driverId);
  const unassingDeliveryUrl = unassignDeliveryFromDriver(delivery?.id, delivery?.driverId);

  const { user } = useAuth();
  const fleetId = user?.fleetId;

  const assignDriver = async () => {
    setSubmitting(true);
    try {
      if (driverId) {
        await axiosPrivate.put(assignDeliveryUrl);
        closeModal();
        toast.success('Order assigned!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong while assigning the order...');
    }
    setSubmitting(false);
  };

  const unassignDriver = async () => {
    setSubmitting(true);
    try {
      if (delivery?.driverId) {
        await axiosPrivate.put(unassingDeliveryUrl);
        closeModal();
        toast.success('Order unassigned!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong while un-assigning the order...');
    }
    setSubmitting(false);
  };

  const displayDriverName = (driverId) => {
    const driver = drivers?.find((driver) => driver.id === driverId);
    return driver?.name;
  };

  const availableDrivers = drivers?.filter((driver) => driver.paused !== 1);

  const trackingUrl = `${process.env.REACT_APP_TRACKING_URL}/track/${delivery?.orderReference}`;

  return (
    <>
      <div className="mt-2">
        <div className="flex text-lg">
          <p className="">Order Reference: </p>
          <p className="ml-2 font-bold">{delivery.orderReference}</p>
        </div>
        {delivery?.externalOrderReference && (
          <div className="flex text-lg">
            <p className="">External Order Reference: </p>
            <p className="ml-2 font-bold">{delivery?.externalOrderReference}</p>
          </div>
        )}
        <div className="flex text-lg">
          <p className="">Created At: </p>
          <p className="ml-2 font-bold">{dayjs(delivery.createdAt).format('MMM DD, YYYY HH:mm')}</p>
        </div>
        <div className="my-2 border-b border-gray-200"></div>
        {delivery.fleetName && (
          <div className="flex text-lg">
            <p className="">Fleet Name: </p>
            <p className="ml-2 font-bold">{delivery.fleetName}</p>
          </div>
        )}
        {delivery.businessName && (
          <div className="flex text-lg">
            <p className="">Business Name: </p>
            <p className="ml-2 font-bold">{delivery.businessName}</p>
          </div>
        )}
        {delivery.branchName && (
          <div className="flex text-lg">
            <p className="">Branch Name: </p>
            <p className="ml-2 font-bold">{delivery.branchName}</p>
          </div>
        )}
        {delivery.branchAddress && delivery.branchCity && (
          <div className="flex text-lg">
            <p className="whitespace-nowrap">Pickup Address: </p>
            <p className="ml-2 font-bold text-left">
              {delivery.branchAddress}, {delivery.branchCity}
              {delivery.branchPostCode && `, ${delivery.branchPostCode}`}
            </p>
          </div>
        )}
        {delivery.arrivedForPickupTime && (
          <div className="flex text-lg">
            <p className="">Arrived for pickup time: </p>
            <p className="ml-2 font-bold">{dayjs(delivery.arrivedForPickupTime).format('MMM DD, YYYY HH:mm')}</p>
          </div>
        )}
        <div className="flex text-lg">
          <p className="">Pickup time: </p>
          <p className="ml-2 font-bold">{dayjs(delivery.pickupTime).format('MMM DD, YYYY HH:mm')}</p>
        </div>
        {fleetId ? (
          delivery.estimatedPickupTime ? (
            <div className="flex text-lg">
              <p className="">Estimated pickup time: </p>
              <p className="ml-2 font-bold">{dayjs(delivery.estimatedPickupTime).format('MMM DD, YYYY HH:mm')}</p>
            </div>
          ) : null
        ) : null}
        {fleetId ? (
          delivery.realPickupTime ? (
            <div className="flex text-lg">
              <p className="">Real pickup time: </p>
              <p className="ml-2 font-bold">{dayjs(delivery.realPickupTime).format('MMM DD, YYYY HH:mm')}</p>
            </div>
          ) : null
        ) : null}
        <div className="my-2 border-b border-gray-200"></div>
        {delivery.customerFullName && (
          <div className="flex text-lg">
            <p className="">Customer Full Name: </p>
            <p className="ml-2 font-bold">{delivery.customerFullName}</p>
          </div>
        )}
        {delivery.customerBusinessName && (
          <div className="flex text-lg">
            <p className="">Customer Company Name: </p>
            <p className="ml-2 font-bold">{delivery.customerBusinessName}</p>
          </div>
        )}
        <div className="flex text-lg">
          <p className="">Customer Phone: </p>
          <p className="ml-2 font-bold">{delivery.customerPhoneNumber || '-'}</p>
        </div>
        <div className="flex text-lg">
          <p className="whitespace-nowrap">Delivery Address: </p>
          <p className="ml-2 font-bold">
            {delivery.deliveryAddress}, {delivery.deliveryCity}, {delivery.deliveryPostCode}
          </p>
        </div>
        <div className="flex text-lg">
          <p className="">Delivery time: </p>
          <p className="ml-2 font-bold">{dayjs(delivery.deliveryTime).format('MMM DD, YYYY HH:mm')}</p>
        </div>
        {fleetId ? (
          delivery.estimatedDeliveryTime ? (
            <div className="flex text-lg">
              <p className="">Estimated delivery time: </p>
              <p className="ml-2 font-bold">{dayjs(delivery.estimatedDeliveryTime).format('MMM DD, YYYY HH:mm')}</p>
            </div>
          ) : null
        ) : null}
        {fleetId ? (
          delivery.realDeliveryTime ? (
            <div className="flex text-lg">
              <p className="">Real delivery time: </p>
              <p className="ml-2 font-bold">{dayjs(delivery.realDeliveryTime).format('MMM DD, YYYY HH:mm')}</p>
            </div>
          ) : null
        ) : null}

        {fleetId && (
          <div className="flex text-lg">
            <p className="">Driver: </p>
            <p className="ml-2 font-bold">{displayDriverName(delivery?.driverId)}</p>
          </div>
        )}
        <div className="my-2 border-b border-gray-200"></div>
        <div className="flex text-lg">
          <p className="">Items: </p>
          <p className="ml-2 font-bold">{delivery.numberOfPackages ? delivery.numberOfPackages : '-'}</p>
        </div>
        <div className="flex text-lg">
          <p className="">Weight: </p>
          <p className="ml-2 font-bold">{delivery?.weight ? delivery.weight : '-'}</p>
        </div>
        <div className="flex max-w-3xl text-lg">
          <p className="">Notes: </p>
          <p className="ml-2 font-bold">{delivery.notes ? delivery.notes : '-'}</p>
        </div>
        <div className="my-2 border-b border-gray-200"></div>

        {!!delivery?.externalOrderPrice && (
          <>
            <div className="flex text-lg">
              <p className="">External Order Price: </p>
              {/* TODO: change to user/account currency */}
              <p className="ml-2 font-bold">{delivery?.externalOrderPrice} kr.</p>
            </div>
          </>
        )}

        {!delivery.openApi && (
          <>
            <div className="flex text-lg">
              <p className="">Base Fee: </p>
              <p className="ml-2 font-bold">{delivery.baseFee}</p>
            </div>
            <div className="flex text-lg">
              <p className="">Distance Fee: </p>
              <p className="ml-2 font-bold">{delivery.distanceFee}</p>
            </div>
            <div className="flex text-lg">
              <p className="">Weight Fee: </p>
              <p className="ml-2 font-bold">{delivery.weightFee}</p>
            </div>
            {!fleetId && (
              <div className="flex text-lg">
                <p className="">Service Fee: </p>
                <p className="ml-2 font-bold">{delivery.serviceFee}</p>
              </div>
            )}
            <div className="flex mt-3 text-2xl">
              <p className="font-bold">Total Fee: </p>
              {/* // REVIEW: toFixed value */}
              <p className="ml-2 font-bold">{fleetId ? `${delivery.totalFeeFleet?.toFixed(1)}` : `${delivery.totalFee.toFixed(1)}`}</p>
            </div>
          </>
        )}
      </div>

      {fleetId && type !== 'preorder' ? (
        delivery.driverId === null && delivery.status !== -1 ? (
          <>
            <div className="my-2 border-b border-gray-200"></div>

            <div className="flex items-end my-4 space-x-2">
              <div className="w-3/4">
                <label className="block text-sm font-medium text-left text-gray-700">Manually assign driver</label>
                <select
                  id="driver"
                  name="driver"
                  autoComplete="driver"
                  onChange={(e) => setDriverId(e.target.value)}
                  className="block w-2/3 px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue focus:border-blue sm:text-sm">
                  <option value={''}>Select a driver</option>
                  {availableDrivers &&
                    availableDrivers.map((driver) => (
                      <option value={driver.id} key={driver.id}>
                        {driver.name}
                      </option>
                    ))}
                </select>
              </div>
              <Button onClick={assignDriver} text="Assign" rounded={true} disabled={driverId === '' || submitting} small />
            </div>
          </>
        ) : delivery.status === 1 || delivery.status === 0 ? (
          <>
            <div className="flex items-center justify-between my-4 space-x-2">
              <label className="block text-sm font-medium text-left text-gray-700">
                Unassign order from <span className="font-bold"> {displayDriverName(delivery.driverId)}</span>:
              </label>
              <Button onClick={unassignDriver} text="Unassign" disabled={submitting} rounded={true} small />
            </div>
          </>
        ) : null
      ) : null}
      <div className="my-2 border-b border-gray-200"></div>
      <a
        href={trackingUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center w-full gap-1 cursor-pointer text-md text-blue hover:opacity-80">
        <p>Track this delivery</p>
        <DynamicIcon icon="LinkIcon" width="w-5" height="h-5" />
      </a>
      <div className="my-2 border-b border-gray-200"></div>
      <div className="flex justify-center mt-4 space-x-4">
        <button
          type="button"
          disabled={submitting}
          className="inline-flex justify-center px-4 py-2 text-sm font-medium bg-blue-100 border rounded-md border-blue text-blue hover:bg-blue hover:text-white focus:outline-none"
          onClick={closeModal}>
          OK
        </button>
      </div>
    </>
  );
};

export default DeliveryDetails;
