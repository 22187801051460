import { useState } from 'react';

import dayjs from 'dayjs';

import DeliveryDetails from '../../../Shared/components/DeliveryDetails';
import Loader from '../../../Shared/components/Loader';
import BaseModal from '../../../Shared/components/Modals/BaseModal';
import TextModal from '../../../Shared/components/Modals/TextModal';
import NoteIcon from '../../../Shared/components/NoteIcon';
import showDeliveryStatus from '../../../Shared/helpers/showDeliveryStatus';
import showNoteCount from '../../../Shared/helpers/showNoteCount';
import NoteFormFleet from './NoteFormFleet';

const OrderTable = ({ deliveries, drivers, getDeliveries, loading, error, type }) => {
  const [infoModal, setInfoModal] = useState(false);
  const [noteModal, setNoteModal] = useState(false);

  const [delivery, setDelivery] = useState(null);

  const headers = [
    { label: 'Order ID', key: 1 },
    { label: 'External Order id', key: 11 },
    { label: 'Notes', key: 14 },
    { label: 'External Order Price', key: 12 },
    { label: 'Business', key: 2 },
    { label: 'Branch pickup', key: 3 },
    { label: 'Customer address', key: 9 },
    { label: 'Company name', key: 13 },
    { label: 'Distance (km)', key: 4 },
    { label: 'Pickup', key: 5 },
    { label: 'Delivery', key: 6 },
    { label: 'Driver', key: 10 },
    { label: 'Status', key: 7 },
    { label: 'Assigned', key: 8 }
  ];

  const toggleInfoModal = () => {
    setInfoModal(!infoModal);
  };

  const toggleNoteModal = () => {
    setNoteModal(!noteModal);
  };

  const generateColor = (delivery) => {
    if (delivery.skipForAssigner && delivery.driverId === null && delivery.status !== -1 && (delivery.openApi === 1 || delivery.openApi === 0)) {
      return 'bg-red-200';
    }
    if (delivery.openApi === 1 && !(delivery.skipForAssigner && delivery.driverId === null && delivery.status)) {
      return 'bg-orange-50';
    }
    return 'bg-white';
  };

  return (
    <div className="relative overflow-x-auto border border-gray-200 rounded-3xl max-h-[600px]">
      <div className="relative rounded-3xl">
        {loading ? (
          <div className="flex justify-center w-full p-5 ">
            <Loader />
          </div>
        ) : error ? (
          <p className="m-5 text-xl text-gray-500">{error}</p>
        ) : (
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase">
              <tr className="sticky top-0 z-10 bg-gray-50">
                {headers?.map((header) => (
                  <th key={header.key} scope="col" className="px-4 py-3 whitespace-nowrap">
                    {header.label}
                  </th>
                ))}
              </tr>
            </thead>
            {deliveries && (
              <tbody>
                {deliveries.map((delivery) => (
                  <tr key={delivery.id} className={`${generateColor(delivery)} border-b`}>
                    <td className="px-4 py-4 font-bold cursor-pointer text-blue w-max hover:opacity-80 whitespace-nowrap">
                      <span
                        className="relative"
                        onClick={() => {
                          toggleInfoModal();
                          setDelivery(delivery);
                        }}>
                        {delivery.orderReference}
                        {delivery?.notes && (
                          <span
                            className="absolute flex items-center justify-center w-2 h-2 text-xs font-bold text-white rounded-full -left-2 -top-2 bg-llYellow"
                            title="This order has a note"></span>
                        )}
                      </span>
                    </td>
                    <td className="px-4 py-4 text-orange-500 whitespace-nowrap">
                      {delivery?.externalOrderReference ? `${delivery?.externalOrderReference}` : '---'}
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap">
                      <NoteIcon
                        counter={showNoteCount(delivery, 'fleet')}
                        onClick={() => {
                          toggleNoteModal(), setDelivery(delivery);
                        }}
                      />
                    </td>
                    {/* TODO: change to user/account currency */}
                    <td className="px-4 py-4 whitespace-nowrap">{delivery?.externalOrderPrice ? `${delivery?.externalOrderPrice} kr.` : '---'} </td>
                    <td className="px-4 py-4 whitespace-nowrap">{delivery.businessName}</td>
                    <td className="px-4 py-4">{delivery.branchName}</td>
                    <td className="px-4 py-4">
                      {delivery.deliveryAddress}, {delivery.deliveryCity}, {delivery.deliveryPostCode}
                    </td>
                    <td className="px-4 py-4">{delivery.customerBusinessName || '---'}</td>
                    <td className="px-4 py-4 whitespace-nowrap">{delivery.distance}</td>
                    <td className="px-4 py-4">{dayjs(delivery.pickupTime).format('HH:mm')}</td>
                    <td className="px-4 py-4">{dayjs(delivery.deliveryTime).format('HH:mm')}</td>
                    <td className="px-4 py-4 whitespace-nowrap">{delivery.driverName ? delivery.driverName : '---'}</td>

                    <td className="px-4 py-4 whitespace-nowrap">{showDeliveryStatus(delivery.status)}</td>
                    <td className="px-4 py-2 whitespace-nowrap">
                      {delivery.driverId === null ? (delivery.skipForAssigner ? '❗️❗️❗️' : '❌') : delivery.acceptedByDriver ? '✅' : '⏳'}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        )}
        {infoModal && delivery && (
          <BaseModal
            closeModal={toggleInfoModal}
            isOpen={infoModal}
            title="Delivery Details"
            body={
              <DeliveryDetails delivery={delivery} drivers={drivers} isOpen={infoModal} title="Delivery Details" closeModal={toggleInfoModal} type={type} />
            }
          />
        )}
        {noteModal && delivery && (
          <TextModal
            closeModal={toggleNoteModal}
            isOpen={noteModal}
            type={delivery?.fleetNote?.length ? 'edit' : 'add'}
            title={delivery?.fleetNote?.length ? 'Edit Note' : 'Add Note'}
            body={
              <NoteFormFleet
                closeModal={toggleNoteModal}
                delivery={delivery}
                getDeliveries={getDeliveries}
                toggleNoteModal={toggleNoteModal}
                labelText="Fleet note:"
                businessNote={delivery?.businessNote ?? ''}
              />
            }
          />
        )}
      </div>
    </div>
  );
};

export default OrderTable;
